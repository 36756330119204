import React from 'react';
import { Router, Route } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import Markdown from 'react-markdown';

import MetadataContext from 'frontend-common/src/contexts/metadata';

import CookiesBanner from 'frontend-common/src/Components/CookiesBanner/CookiesBanner';
import GTag from 'frontend-common/src/Components/GTag/GTag';
import Login from 'frontend-common/src/Screens/Login';

import About from './Screens/About';
import Dashboard, { details } from './Screens/Dashboard';
import Details from './Screens/Details';
import Help from './Screens/Help';
import Home from './Screens/Home';
import NewJob from './Screens/NewJob';
import Privacy from './Screens/Privacy';
import ToS from './Screens/ToS';

import settings from './content/settings/settings.json';
import about from './content/screens/about.json';
import help from './content/screens/help.json';
import privacy from './content/screens/privacy.json';
import tos from './content/screens/tos.json';

const history = createBrowserHistory();

const metadata = {
  contacts: settings.contacts,
  footerMessage: <Markdown>{settings.footer}</Markdown>,
  links: [
    about.published && {
      route: '/about',
      title: about.title,
    },
    help.published && {
      route: '/help',
      title: help.title,
    },
    tos.published && {
      route: '/tos',
      title: tos.title,
    },
    privacy.published && {
      route: '/privacy',
      title: privacy.title,
    },
  ].filter(x => !!x),
  logo: '../static/media/cordax-logo-white.svg',
  siteTitle: settings.title,
};

const LoginComponent = () => <Login onLoginGoTo="/dashboard" />;

const App = () => {
  return (
    <MetadataContext.Provider
      value={ metadata }
    >
      <GTag />
      <CookiesBanner />
      <Router history={ history }>
        <Route exact path="/" component={ Home } />
        {about.published && <Route exact path="/about" component={ About } />}
        {help.published && <Route exact path="/help" component={ Help } />}
        <Route exact path="/dashboard" component={ Dashboard } />
        <Route exact path={ details(':id') } component={ Details } />
        <Route exact path="/newjob" component={ NewJob } />
        <Route
          exact
          path="/login"
          component={ LoginComponent }
        />
        {privacy.published && (
        <Route exact path="/privacy" component={ Privacy } />
        )}
        {tos.published && <Route exact path="/tos" component={ ToS } />}
      </Router>
    </MetadataContext.Provider>
  );
};

export default App;
