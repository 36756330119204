import { useEffect, useMemo } from 'react';

import useConsent from '../../Hooks/useConsent';

// These may come from external/third-party/browser libraries, make sure we don't override.
const _gtag = (typeof window !== 'undefined' && window.gtag) || null;
const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];

export function gtag() {
  // gtag expects an instance of Arguments (for some reason), not an array, which a spread of arguments would create.
  // eslint-disable-next-line prefer-rest-params
  const args = arguments;

  if (_gtag) {
    _gtag(...args);
  }
  else {
    dataLayer.push(args);
  }
}

// Expose to the global scope, as is expected of standard gtag implementations.
if (typeof window !== 'undefined') {
  window.dataLayer = dataLayer;
  window.gtag = gtag;

  // Order is important! This is meant to be the very first entry in dataLayer.
  gtag('js', new Date());
}

// Keep this as a global object. In case where the GTag component is unmounted (even though it should never be), we
// still want to keep track of whether we have sent a first consent call during this browsing session, as a second one
// would not take effect; we can only update from then on.
let consentModeSet = false;

const GTag = () => {
  const [ consent ] = useConsent();

  // Order is important! These must be sent before any `config` or `event` calls.
  useEffect(() => {
    const consentType = consentModeSet ? 'update' : 'default';

    gtag('consent', consentType, {
      ad_storage: (consent?.ads ? 'granted' : 'denied'),
      ad_user_data: (consent?.ads ? 'granted' : 'denied'),
      ad_personalization: (consent?.ads ? 'granted' : 'denied'),
      analytics_storage: (consent?.analytics ? 'granted' : 'denied'),
    });

    // Note that there is no need to resend events from the dataLayer, as they are automatically reprocessed by
    // analytics after the user grants permission (this is not reflected in the realtime views, only later in the
    // reports).

    consentModeSet = true;
  }, [ consent?.ads, consent?.analytics ]);

  const gtagIds = useMemo(
    () => (process.env.REACT_APP_GTAG_ID ?? '').split(','),
    [],
  );

  // Order is important! This must be sent after the first `consent` call with `default` values.
  useEffect(() => {
    gtagIds.forEach(id => gtag('config', `${id}`));
  }, [ gtagIds ]);

  useEffect(() => {
    if (gtagIds[0]) {
      const script = document.createElement('script');

      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagIds[0]}`;
      script.async = true;
      script.id = 'gtag-src';

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }

    return () => {};
  }, [ gtagIds ]);

  return null;
};

export default GTag;
