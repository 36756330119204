import React from 'react';
import Markdown from 'react-markdown';

import Viewport from 'frontend-common/src/Components/Viewport';

import privacy from '../content/screens/privacy.json';

const Privacy = () => (
  <Viewport
    simplePage
    title={ privacy.title }
    dashboardBtn
    logoutBtn
  >
    <Markdown>{ privacy.body }</Markdown>
  </Viewport>
);

export default Privacy;
