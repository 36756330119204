import React from 'react';
import { string } from 'prop-types';

import IconCheckmark from '../Icon/Checkmark';

const FormCheckbox = ({
  label,
  name,
  ...props
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className="form-checkbox"
      name={ name }
    >
      <input
        className="form-checkbox__input"
        id={ name }
        name={ name }
        type="checkbox"
        { ...props }
      />
      <IconCheckmark />
      { label }
    </label>
  );
};

FormCheckbox.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
};

export default FormCheckbox;
