import React from 'react';
import Markdown from 'react-markdown';

import Viewport from 'frontend-common/src/Components/Viewport';

import tos from '../content/screens/tos.json';

const ToS = () => (
  <Viewport
    simplePage
    title={ tos.title }
    dashboardBtn
    logoutBtn
  >
    <Markdown>{ tos.body }</Markdown>
  </Viewport>
);

export default ToS;
