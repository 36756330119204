import React from 'react';

const IconCheckmark = () => {
  return (
    <svg
      className="icon-checkmark"
      fill="none"
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* eslint-disable-next-line max-len */}
      <path d="M2.69433 7.6145C2.17935 7.12322 1.36925 7.12323 0.854263 7.6145C0.303777 8.13964 0.303779 9.01826 0.854266 9.54339L5.01478 13.5123C5.59433 14.0652 6.50599 14.0652 7.08553 13.5123L18.8323 2.30655C19.3827 1.78141 19.3827 0.90279 18.8323 0.377655C18.3173 -0.113609 17.5072 -0.113608 16.9922 0.377657L6.05016 10.8158L2.69433 7.6145Z" fill="currentColor" />
    </svg>
  );
};

export default IconCheckmark;
