import Cookies from 'js-cookie';

const fauxCookies = new Map();

export const getCookies = options => {
  // Faux implementation for server-side rendering, don't think it's actually used though
  if (typeof window === 'undefined') {
    return {
      get: key => {
        try {
          return JSON.parse(fauxCookies.get(key));
        }
        catch (ex) {
          return null;
        }
      },
      set: (key, value) => fauxCookies.set(key, JSON.stringify(value)),
      remove: key => fauxCookies.delete(key),
    };
  }

  const cookieOptions = {
    expires: 365,
    sameSite: 'strict',
    ...options,
  };

  const domain = options?.domain ?? window.location.hostname;
  if (domain) {
    cookieOptions.domain = `.${domain}`;
  }

  return {
    get: key => {
      try {
        const item = Cookies.get(key);
        return JSON.parse(item);
      }
      catch (ex) {
        return null;
      }
    },
    set: (key, value) => {
      const newValue = JSON.stringify(value);
      const oldValue = Cookies.get(key);
      if (oldValue !== newValue) {
        Cookies.set(key, newValue, cookieOptions);
      }
    },
    remove: key => {
      if (Cookies.get(key)) {
        Cookies.remove(key, cookieOptions);
      }
    },
  };
};

const cookies = getCookies();

export default cookies;
